import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements';
import { FieldConfig } from '../field.interface';
import { Constant } from 'src/app/constants/constant';
import { ProductListiingService } from 'src/app/services/product-listiing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  exportAs: "dynamicForm",
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
  @Input() fields: FieldConfig[] = [];
  @Input() layout: any = 2;
  @Input() gapBetweenImage: any = '5px';
  @Input() displayMobileImage: any = 2;
  @Input() mobileGapBetweenImage: any = 2;
  @Input() customField13: any = 0;
  @Input() customField5: any = 0;

  @Input() newFormField: any = 0;

  objectTobeDisplayed: any;

  minusValue: any = '2px';
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  formInit: boolean = false;

  formInit1: boolean = false;

  enableConditionalField?: any;
  @Input() patchValueList: any[] = [];
  previousFormField: any;
  indexNew: number = 0;
  valuesInputed: any = [];
  dontDisplayBackButton: boolean = false; imageList: any = [];

  previousFormFieldsList: any[] = [];

  text: string;
  initSecondForm: boolean = false;
  formInitSecond: boolean = false;
  EnablePayment: any;

  get value() {
    return this.form.value;
  }

  constructor(private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private productListiingService: ProductListiingService,
  ) { }

  reInit: any = true;
  ngOnInit() {


    if (this.customField13 == 1) {


      this.initSecondForm = true;
    }

    this.form = this.createControl();
    this.subscribeToFormChanges();

    this.valuesInputed.push(this.fields[0].name)
    this.formInit = true;
    this.previousFormField = this.fields[0].name;
    this.objectTobeDisplayed = this.fields[0];
    this.formInit1 = true;
    this.indexNew = 0;

    this.EnablePayment = this.customField5;
  }

  onSubmit() {
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field['dynamicFormLabelValidator'] || [])
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  subscribeToFormChanges() {
    this.form.valueChanges.subscribe(() => {
      this.updateConditionalFieldsVisibility();
    });
  }

  updateConditionalFieldsVisibility() {
    this.fields.forEach(field => {
      if (field.enableConditionalField == 1) {
        const conditionResult = this.evaluateCondition(field.conditionText, this.form.value);
  
        if (conditionResult) {

          let index = this.valuesInputed.findIndex(x => x == field.name);

          if (index < 0) {
            this.objectTobeDisplayed = field;
            this.formInit1 = true;
            this.valuesInputed.push(this.objectTobeDisplayed.name)
            this.indexInital = this.indexInital + 1;
          }



          if (this.valuesInputed.length > 1) {
            this.dontDisplayBackButton = true;
          }

        }


        field.visible = conditionResult;
      }
    });
  }

  evaluateCondition(conditionText: string, formValues: any): boolean {
    if (!conditionText) return true; // If no condition specified, return true
    return this.evaluateExpression(conditionText, formValues);
  }

  evaluateExpression(expression: string, formValues: any): boolean {
    if (!expression) return true;

    expression = expression.replace(/\s+or\s+/g, ' || ');


    const blocks = expression.split(/\s*\|\|\s*/);

    for (const block of blocks) {
      // Split block by AND (&&) operator
      const conditions = block.split(/\s*&&\s*/);
      let allConditionsTrue = true;

      for (const condition of conditions) {
        const conditionResult = this.evaluateSingleCondition(condition.trim(), formValues);
        if (!conditionResult) {
          allConditionsTrue = false;
          break;
        }
      }

      if (allConditionsTrue) {
        return true; // If all conditions in a block are true, the OR block is true
      }
    }

    return false; // If none of the OR blocks are true
  }

  evaluateSingleCondition(condition: string, formValues: any): boolean {
    const [variable, operator, value] = condition.split(/\s*(===|!==|==|!=|<|>|<=|>=)\s*/);
    const variableValue = formValues[variable];
    const cleanValue = value.replace(/'/g, '');
    const variableValueNumber = parseFloat(variableValue);

    switch (operator) {
      case '===':
        return variableValue === cleanValue;
      case '!==':
        return variableValue !== cleanValue;
      case '==':
        return variableValue == cleanValue || variableValueNumber == parseFloat(cleanValue);
      case '!=':
        return variableValue != cleanValue && variableValueNumber != parseFloat(cleanValue);
      case '<':
        return variableValueNumber < parseFloat(cleanValue);
      case '>':
        return variableValueNumber > parseFloat(cleanValue);
      case '<=':
        return variableValueNumber <= parseFloat(cleanValue);
      case '>=':
        return variableValueNumber >= parseFloat(cleanValue);
      default:
        return false;
    }
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        let validator: any = '';
        if (valid.name === 'required') {
          validator = Validators.required;
          validList.push(validator);
        } else if (valid.name === 'pattern') {
          validator = Validators.pattern(valid.validator);
          validList.push(validator);
        }
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  calculateFlexValue(layout: number, gapBetweenImage: boolean): string {
    const baseFlex = layout === 2 ? '50%' :
      layout === 3 ? '33.33%' :
        layout === 4 ? '25%' : '100%';
    return gapBetweenImage ? `calc(${baseFlex} - ${gapBetweenImage})` : baseFlex;
  }

  indexInital: any = 0;


  onFieldValueChange(value: any) {


    this.previousFormField = value;

    this.previousFormFieldsList.push(value);


  }



  onFieldValueChangeImage(value: any) {
    this.imageList = value;
    this.onFieldValueChange(value);

    this.formInitSecond = true;

  }

  SubmitForm(object) {

    if (object.operationType = "New") {
      this.productListiingService.submitProductListing(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.router.navigate(['/home']);
            
          }
          else {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }



  }

  getIndex() {

    this.indexInital = this.indexInital;



  }

  back() {

    let length = 0;


    let newLength = this.valuesInputed.length - 1;


    let indexNew = this.fields.findIndex(x => x['name'] == this.valuesInputed[newLength]);

    if (indexNew > -1) {

      if (this.form.value[this.fields[indexNew].name] != '' && this.form.value[this.fields[indexNew].name] != null) {
        this.form.get(this.fields[indexNew].name).setValue('');
      }

    }
  indexNew = this.fields.findIndex(x => x['name'] == this.valuesInputed[newLength-1]);

    if (indexNew > -1) {

      if (this.form.value[this.fields[indexNew].name] != '' && this.form.value[this.fields[indexNew].name] != null) {
        this.form.get(this.fields[indexNew].name).setValue('');
      }

    }



    if (this.valuesInputed.length == 1) {
      this.dontDisplayBackButton = false;
      length = 0;
    } else {

      this.valuesInputed = this.valuesInputed.slice(0, -1);
      length = this.valuesInputed.length - 1;

    }


    if (length == 0) {
      this.dontDisplayBackButton = false;
    }

    let index = this.fields.findIndex(x => x['name'] == this.valuesInputed[length]);

    if (index > -1) {

      this.objectTobeDisplayed = this.fields[index];
    }


  }

  goToState(index: number) {
    if (index >= 0) {
      
      for (let i = index + 1; i < this.fields.length; i++) {
        if (this.form.get(this.fields[i].name)) {
          this.form.get(this.fields[i].name).setValue('');
        }
      }
  
      this.valuesInputed = this.fields.slice(0, index + 1).map(field => field.name);
  
      if (index == 0) {
        this.valuesInputed = [this.fields[0].name];
        this.dontDisplayBackButton = false;
      }
  
      this.objectTobeDisplayed = this.fields[index];
    }
  }
  

  submitSecondForm(value) {

    console.log(value);
    
    const mergedObj = { ...this.form.value, ...value };

    mergedObj.productListingImages = this.imageList;

    mergedObj['value'] = JSON.stringify(mergedObj);
    this.SubmitForm(mergedObj)


  }


}