import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPriceService {

  private subscriptionPriceSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionPrice'));
  private subscriptionTaxPercentageSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionTaxPercentage'));
  private subscriptionTotalCostSubject = new BehaviorSubject<any>(localStorage.getItem('subscriptionTotalCost'));
  private subscriptionTotalTaxAmount = new BehaviorSubject<any>(localStorage.getItem('subscriptionTotalTaxAmount'));
  private totalGrandTotal = new BehaviorSubject<any>(localStorage.getItem('totalGrandTotal'));

  subscriptionPrice$ = this.subscriptionPriceSubject.asObservable();
  subscriptionTaxPercentage$ = this.subscriptionTaxPercentageSubject.asObservable();
  subscriptionTotalCost$ = this.subscriptionTotalCostSubject.asObservable();
  subscriptionTotalTaxAmount$ = this.subscriptionTotalTaxAmount.asObservable();

  GrandTotalAmount$ = this.totalGrandTotal.asObservable();

  updateSubscriptionDetails(price: any, taxPercentage: any, totalCost: any,taxAmount:any) {
    localStorage.setItem('subscriptionPrice', price);
    localStorage.setItem('subscriptionTaxPercentage', taxPercentage.toString());
    localStorage.setItem('subscriptionTotalCost', totalCost.toString());
    localStorage.setItem('subscriptionTotalTaxAmount', taxAmount.toString());
    this.subscriptionPriceSubject.next(price);
    this.subscriptionTaxPercentageSubject.next(taxPercentage);
    this.subscriptionTotalCostSubject.next(totalCost);
    this.subscriptionTotalTaxAmount.next(taxAmount);
  }


  updateGrandTotal(totalGrandTotal: any) {
    localStorage.setItem('totalGrandTotal', totalGrandTotal.toString());
    this.totalGrandTotal.next(totalGrandTotal);
  }
  
}
