import { Component, Input } from '@angular/core';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent {

  @Input() type: any = 1;

  subscriptionCost: any = 0.0;
  taxPercentage: any = 0.0;
  totalCost: any = 0.0;
  taxAmount: any = 0.0;
  totalGrandTotal: any = 0.0;


  constructor(private subscriptionService : SubscriptionPriceService,) { }

  ngOnInit() {
    this.subscriptionService.subscriptionPrice$.subscribe(price => {
      this.subscriptionCost = price;
    });

    this.subscriptionService.subscriptionTaxPercentage$.subscribe(taxPercentage => {
      this.taxPercentage = taxPercentage;
    });

    this.subscriptionService.subscriptionTotalCost$.subscribe(totalCost => {
      this.totalCost = totalCost;
    });

    this.subscriptionService.subscriptionTotalTaxAmount$.subscribe(taxAmount => {
      this.taxAmount = taxAmount;
    });

    this.subscriptionService.GrandTotalAmount$.subscribe(totalGrandTotal => {
      this.totalGrandTotal = totalGrandTotal;
    });
  }


}

